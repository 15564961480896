$(document).ready(function() {
    $('.piluku-preloader').addClass('hidden');

    // Touch items

    $('.left-bar .menu-parent > li a').on('click touchend', function(e) {
        var el = $(this);
        var link = el.attr('href');
        window.location = link;
    });


    // $(document).on('click touchstart', '.left-bar .menu-parent > li a', function(e) {
    //     alert('Believe in yourselves. Dream. Try. Do good.');
    //   });
});