'use strict';
$(document).ready(function () {
    $('body').on('click','angular2-multiselect .c-btn',function(){
        $('.dropdown-list ul').animate({scrollTop:0},0);
    })
    $('[data-toggle=tooltip]').tooltip();

    $("a.tab_active_a").parent().parent().siblings().addClass("activate");
    //$("a.tab_active_a").parent().parent().css({"display":"block","transition":"all 2s linear","opacity": "1"});
    $('a.tab_active_a').closest('ul.submenu').addClass('onload-open-sidebar-menu');
    // $("a.tab_active_a").parent().parent().siblings().find('.chevron').toggleClass('fa-angle-down')
    //                            .removeClass('fa-angle-up');
   // $("a.activate").next().css({"display":"block","transition":"all 2s linear","opacity": "1"});
    // $("a.tab_active_a").parent().parent().css({"display":"block","transition":"all 2s linear","opacity": "1"});
    // if(! $('a.activate').parent().find('ul.submenu').is(':visible')){
    //     alert('not visible');
    // }
    // $(this).find('.chevron').toggleClass('fa-angle-down')
    //             	           .addClass('fa-angle-up');
    
    // leftbar toggle for minbar
    if($(window).width()>760){
        var nice = $(".left-bar").niceScroll();
    }
    $('.menu-bar').click(function(){
        $('body').find('.bgc_headr.bgc_main').removeClass('open_box');
        $(".wrapper").toggleClass('mini-bar');
        //Added when sidebar opens and removed when closed.
        $(".wrapper").toggleClass('open-sidebar');

        if($(window).width()>760){
            $(".left-bar").getNiceScroll().remove();
            setTimeout(function() {
                $(".left-bar").niceScroll();
            }, 200);
        }
    });

    // mobile menu 
    $('.menu-bar-mobile').on('click', function (e) {
        // $(this).addClass('menu_appear');
        
        if($(window).width()>760){
            $(".left-bar").getNiceScroll().remove();
        }

        $('body').find('.bgc_headr.bgc_main').removeClass('open_box');
        $( ".left-bar" ).toggleClass("menu_appear" );
        $( ".overlay" ).toggleClass("show" );
        if($(window).width()>760){
            setTimeout(function() {
                $(".left-bar").niceScroll();
            }, 200);
        }
    });

    // orvelay hide menu
    $(".overlay").on('click',function(){
        $( ".left-bar" ).toggleClass("menu_appear" );
        $(this).removeClass("show");
    });

    //onlclick of menus hide the sidebar on mobiles
    //Added by Ammar on 23may18
    // $("#mainMenu li").on('click',function(){
    //     $( ".left-bar" ).toggleClass("menu_appear" );
    //     $( ".overlay" ).toggleClass("show" );
    // });

    $(".mobile-ti-menu").on('click',function(){
        $( ".left-bar" ).toggleClass("menu_appear" );
        $( ".overlay" ).toggleClass("show" );
    });

    // $(".menu-parent li a").on("touchend", function(event) {
    //     window.location.href = $(this).attr("href");
    // });
    $(".menu-parent>li>a.level1").on("click", function(e) {
       // alert('test');
        $('body').find('.bgc_headr.bgc_main').removeClass('open_box');
       $('.menu-parent>li>a.level1').removeClass('activate');
       $('ul.submenu').removeClass('onload-open-sidebar-menu');
        var el = $(this);
        var link = el.attr("href");
        if($(this).find('.chevron').hasClass('fa-angle-down') || $(this).find('.chevron').hasClass('fa-angle-up')){
            //alert('in submenu');
            e.preventDefault();
            //$('ul.submenu').css('display','none');
            
            if($(this).parent().find('ul.submenu').is(':visible'))
            {
                //$(this).parent().find('ul.submenu').css('display','none', 'transition', 'all 2s linear');
                $(this).parent().find('ul.submenu').css({"display":"none","transition":"unset", "opacity": "0"});
                $(this).find('.chevron').toggleClass('fa-angle-down')
                               .removeClass('fa-angle-up');
                
            }
            else{
                $('ul.submenu').css({"display":"none","transition":"unset", "opacity": "0"});
                $('.chevron').toggleClass('fa-angle-down').addClass('fa-angle-down');
                $(this).parent().find('ul.submenu').css({"display":"block","transition":"all 2s linear","opacity": "1"});
                $(this).find('.chevron').toggleClass('fa-angle-down')
                	           .addClass('fa-angle-up');
                //$(this).parent().find('ul.submenu').css('display','block');
            }

            
            //$(this).parent().find('ul.submenu').toggleClass('display-none');
            $(this).addClass('activate');
        }
        else{
            if(!$(".wrapper").hasClass('mini-bar') || $(window).width()<701){
                $( ".left-bar" ).removeClass("menu_appear" );
                $( ".overlay" ).removeClass("show" );
                //this.router.navigateByUrl(link);
                    $(".wrapper").addClass('mini-bar');
                    $(".wrapper").removeClass('open-sidebar');

                if($(window).width() < 1199){
                    //Added when sidebar opens and removed when closed.
            
                    if($(window).width()>760){
                        $(".left-bar").getNiceScroll().remove();
                        setTimeout(function() {
                            $(".left-bar").niceScroll();
                        }, 200);
                    }
                }
                window.location = link;
            
            }
        }
        $(this).addClass('activate');
    });
    $(".menu-parent > li > ul > li > a.level2").on("click", function(e) {
        // alert('test');
         var el = $(this);
         var link = el.attr("href");
        
        $('body').find('.bgc_headr.bgc_main').removeClass('open_box');
        $( ".left-bar" ).removeClass("menu_appear" );
        $( ".overlay" ).removeClass("show" );
            $(".wrapper").addClass('mini-bar');
            $(".wrapper").removeClass('open-sidebar');
        //$('ul.submenu').css({"display":"none","transition":"unset", "opacity": "0"});
        //this.router.navigateByUrl(link);
        if($(window).width() < 1199){
            //Added when sidebar opens and removed when closed.
    
            if($(window).width()>760){
                $(".left-bar").getNiceScroll().remove();
                setTimeout(function() {
                    $(".left-bar").niceScroll();
                }, 200);
            }
        }
        window.location = link;
        
     });

    // right side-bar toggle
    $('.right-bar-toggle').on('click', function(e){
        e.preventDefault();
        $('.wrapper').toggleClass('right-bar-enabled');
    });
    


    $('ul.menu-parent').accordion();

    new WOW().init();

    // PANELS
    // panel close
    $('.panel-close').on('click', function (e) {
        e.preventDefault();
        $(this).parent().parent().parent().parent().addClass(' animated fadeOutDown');
    });


    $('.panel-minimize').on('click', function (e)
    {
        e.preventDefault();
        var $target = $(this).parent().parent().parent().next('.panel-body');
        if ($target.is(':visible')) {
            $('i', $(this)).removeClass('fa-angle-up').addClass('fa-angle-down');
        } else {
            $('i', $(this)).removeClass('fa-angle-down').addClass('fa-angle-up');
        }
        $target.slideToggle();
    });

    $('.panel-refresh').on('click', function (e)
    {
        e.preventDefault();
        // alert('vj');
        var $target = $(this).closest('.panel-heading').next('.panel-body');
        $target.mask('<i class="fa fa-refresh fa-spin"></i> Loading...');

        setTimeout(function () {
            $target.unmask();
            console.log('ended');
        },1000);
    });

    //Active menu item expand automatically on reload or fresh open

    if (!$('.wrapper').hasClass("mini-bar") && $(window).width() > 1200) {
        $('.submenu li.active').closest('.submenu').addClass('current');
        var activeMenu = $('.submenu li.current').closest('ul').css('display','block');
    }

    // if ($('.level1').hasClass("has-child") && $(".level > ul.submenu > li > a").hasClass("tab_active_a")){

    // }
   

    if($(".mail_list").length > 0){
        $(".mail_list").niceScroll();
    }

    if($(".mails_holder").length > 0){
        $(".mails_holder").niceScroll();
    }

    if($(".mail_brief_holder").length > 0){
        $(".mail_brief_holder").niceScroll();
    }

    if($("#paginator").length > 0){
        $('#paginator').datepaginator();
    }

    if($(".table-row").length > 0){
        $('.table-row').on('click', function(){
            // $('.table-row').removeClass('active');
            $(this).toggleClass('active');
        });
    }

    if($(".pick-a-color").length > 0){
        $(".pick-a-color").pickAColor({
          showSpectrum            : true,
            showSavedColors         : true,
            saveColorsPerElement    : true,
            fadeMenuToggle          : true,
            showAdvanced            : true,
            showBasicColors         : true,
            showHexInput            : true,
            allowBlank              : true,
            inlineDropdown          : true
        });
    }


    if($('#picker').length > 0){
        $('#picker').colpick({
            flat:true,
            layout:'hex',
            submit:0
        });
    }

    var endDate = "June 7, 2087 15:03:25";
    if($('.countdown.styled').length > 0){
        $('.countdown.styled').countdown({
            date: endDate,
            render: function(data) {
                $(this.el).html("<div>" + this.leadingZeros(data.years, 2) + " <span>years</span></div><div>" + this.leadingZeros(data.days, 2) + " <span>days</span></div><div>" + this.leadingZeros(data.hours, 2) + " <span>hrs</span></div><div>" + this.leadingZeros(data.min, 2) + " <span>min</span></div><div>" + this.leadingZeros(data.sec, 2) + " <span>sec</span></div>");
            }
        });
    }

    if($(".openNav").length > 0){
        $(".openNav").click(function() {
            $(this).toggleClass("open");
        });
    }

    if($("#elm1").length > 0){
        tinymce.init({
            selector: "textarea#elm1",
            theme: "modern",
            height:300,
            plugins: [
                "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
                "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                "save table contextmenu directionality emoticons template paste textcolor"
            ],
            toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | l      ink image | print preview media fullpage | forecolor backcolor emoticons",
            style_formats: [
                {title: 'Bold text', inline: 'b'},
                {title: 'Red text', inline: 'span', styles: {color: '#ff0000'}},
                {title: 'Red header', block: 'h1', styles: {color: '#ff0000'}},
                {title: 'Example 1', inline: 'span', classes: 'example1'},
                {title: 'Example 2', inline: 'span', classes: 'example2'},
                {title: 'Table styles'},
                {title: 'Table row 1', selector: 'tr', classes: 'tablerow1'}
            ]
        });
    }
});
function openFilter(){
    if($('body').find('.bgc_headr.bgc_main').hasClass('open-sidebar')){
        $('body').find('.bgc_headr.bgc_main').removeClass('open-sidebar').addClass('mini-bar')
    }
    $('body').find('.bgc_headr.bgc_main').toggleClass('open_box');
}
$(document).on('click',function(e){
    if(!($(e.target).hasClass('filter_box') || $(e.target).parents().hasClass('filter_box') || $(e.target).parents().hasClass('filter_btn') || $(e.target).hasClass('filter_btn') || $(e.target).hasClass('daterangepicker') || $(e.target).parents().hasClass('daterangepicker'))){
    $('body').find('.bgc_headr.bgc_main').removeClass('open_box');
    }
})